<script lang="ts">
    import { Label } from '$lib/components/ui/label';
    import { Input } from '$lib/components/ui/input';
    import { Textarea } from '$lib/components/ui/textarea';
    import { Button } from '$lib/components/ui/button';

    let postId: string = '';
    let message: string = '';

    function handleSubmit(e: Event) {
        e.preventDefault();

        console.log('Form submitted:', { postId, message });
    }
</script>

<div class="mt-3 h-auto max-w-full space-y-1 rounded-md text-white">
    <p class="m-0 w-full max-w-[900px] p-0 text-[17px] text-[black] dark:text-white">
        To initiate an appeal, submit the form below.
        <strong>We’ll be in touch within 3 business days.</strong>
    </p>
    <form on:submit|preventDefault="{handleSubmit}" class="mt-3 space-y-6 md:max-w-[550px]">
        <div class="flex flex-col space-y-1 pt-2">
            <Label for="postId" class="font-bold text-black dark:text-white">Post-ID *</Label>
            <Input
                id="postId"
                placeholder="e.g. 123123"
                bind:value="{postId}"
                class="!focus-visible:ring-0 !focus-visible:ring-offset-0 w-ful h-[40px] text-black focus:outline-none focus:ring-0 focus:ring-offset-0 focus-visible:ring-0 focus-visible:ring-offset-0  dark:text-white md:w-[80%]"
            />
        </div>
        <div class="flex flex-col space-y-1">
            <Label for="message" class="font-bold text-black dark:text-white">Message *</Label>
            <Textarea
                id="message"
                placeholder="Lorem Ipsum Dolor Sit"
                bind:value="{message}"
                class="x !focus-visible:ring-0 !focus-visible:ring-offset-0 h-40 w-full  text-black focus:outline-none focus:ring-0 focus:ring-offset-0 focus-visible:ring-0 focus-visible:ring-offset-0  dark:text-white md:w-[80%]"
            />
        </div>
        <Button type="submit" class="w-full bg-[#D9D9D9] text-black hover:bg-gray-400 md:w-[80%]">Send</Button>
    </form>
</div>
